import React, { Component } from 'react';

export default class ProjectHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeProject: null,
    };
  }

  renderProject = () => {
    const { activeProject } = this.state;
    switch (activeProject) {
      default:
        return <div>Project Page</div>;
    }
  };

  render() {
    //TODO: Project Container styling
    return <div className="ProjectContainer">{this.renderProject()}</div>;
  }
}
