import React, { Component } from 'react';
import './App.css';
import Header from './Components/Header';
import SectionHandler from './Components/SectionHandler';
import NavigationMenu from './Components/NavigationMenu';
import Row from './Common/Row';
import Column from './Common/Column';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: 'About',
    };
  }

  onNavSelect = section => {
    this.setState({ activeSection: section });
  };

  render() {
    const { activeSection } = this.state;
    return (
      <div className="App">
        <Row
          justify
          align
          style={{
            fontSize: 18,
            margin: 100,
          }}
        >
          <div>
            It's time to build, baby.
          </div>
          {/**<NavigationMenu
            activeSection={activeSection}
            onNavSelect={this.onNavSelect}
          />
          <Column style={{ flex: 1 }}>
            <Header onNavSelect={this.onNavSelect} />
            <SectionHandler section={activeSection} />
          </Column>**/}
        </Row>
      </div>
    );
  }
}

export default App;
