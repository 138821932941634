import React from 'react';
import PropTypes from 'prop-types';

const Column = ({ children, style, justify, align, className, ...props }) => (
  <div
    className={className}
    style={{
      display: 'flex',
      flexDirection: 'column',
      ...(justify && { justifyContent: 'center' }),
      ...(align && { alignItems: 'center' }),
      ...style,
    }}
    {...props}
  >
    {children}
  </div>
);

Column.propTypes = {
  style: PropTypes.object,
  justify: PropTypes.bool,
  align: PropTypes.bool,
};

Column.defaultProps = {
  style: {},
  justify: false,
  align: false,
};

export default Column;
