import React from 'react';
import PropTypes from 'prop-types';

const Row = ({ children, style, justify, align, className }) => (
  <div
    className={className}
    style={{
      display: 'flex',
      ...(justify && { justifyContent: 'center' }),
      ...(align && { alignItems: 'center' }),
      ...style,
    }}
  >
    {children}
  </div>
);

Row.propTypes = {
  children: PropTypes.object.isRequired,
  style: PropTypes.object,
  justify: PropTypes.bool,
  align: PropTypes.bool,
};

Row.defaultProps = {
  style: {},
  justify: false,
  align: false,
};

export default Row;
