import React, { Component } from 'react';
import About from './Pages/About';
import Contact from './Pages/Contact';
import ProjectHandler from './Pages/ProjectHandler';
import { ABOUT, CONTACT, PROJECTS } from '../Constants/PageNames';

export default class SectionHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderSection = () => {
    const { section } = this.props;
    switch (section) {
      case ABOUT:
        return <About />;
      case CONTACT:
        return <Contact />;
      case PROJECTS:
        return <ProjectHandler />;
      default:
        return <About />;
    }
  };

  render() {
    //TODO: Section Container styling
    return <div className="SectionContainer">{this.renderSection()}</div>;
  }
}
