import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Button.css';

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPosition: null,
      DELTA: props.delta,
    };
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
  }

  handleMouseDown(e) {
    this.setState({ prevPosition: { x: e.pageX, y: e.pageY } });
  }

  handleMouseUp(e) {
    const { onClick } = this.props;
    const { prevPosition, DELTA } = this.state;

    if (prevPosition) {
      if (
        Math.abs(prevPosition.x - e.pageX) < DELTA &&
        Math.abs(prevPosition.y - e.pageY) < DELTA
      ) {
        onClick && onClick(e);
      }
    }
  }

  render() {
    const { children, buttonStyles, className, disabled, loading } = this.props;
    return (
      <button
        type="button"
        disabled={disabled || loading}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        style={buttonStyles}
        className={`genericButtonStyle ${className}`}
      >
        {loading ? <div className="loading" /> : children}
      </button>
    );
  }
}

Button.propTypes = {
  buttonStyles: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  delta: PropTypes.number,
};

Button.defaultProps = {
  delta: 0.5,
};

export default Button;
